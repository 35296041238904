.header {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #028090;
  padding: 0;
}

.logo {
  font-size: 1rem;
  color: #f0f3bd;
  font-weight: bold;
  margin-left: 10px;
}

.header ul {
  list-style: none;
  margin-right: 10px;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.header li {
  margin-left: 3rem;
}

.header a {
  text-decoration: none;
  font-size: 1rem;
  color: #f0f3bd;
}

.header a:hover,
.header a:active,
.header a.active {
  color: white;
}

/* .modal {
  background-color: #028090;
  color: white;
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 30%;
  z-index: 3;
  border-radius: 10px;
}
.modal p {
  margin: 10px;
} */

.modal {
  background-color: #028090;
  color: white;
  position: fixed; /* Changed from absolute to fixed */
  top: 50%; /* Changed for centering */
  left: 50%; /* Changed for centering */
  transform: translate(-50%, -50%); /* Centering */
  width: 80%;
  max-width: 500px; /* Limit width */
  height: auto;
  z-index: 1000; /* High z-index to ensure it's on top */
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Add shadow */
  padding: 20px; /* Add padding */
  font-family: "Comic Sans MS", "Chalkboard SE", "Marker Felt", sans-serif; /* Fun font */
  opacity: 0;
  animation: modalOpen 0.5s forwards; /* Animation */
}

/* For the greyed out background */
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999; /* Slightly lower z-index */
  opacity: 0;
  animation: modalOpen 0.5s forwards; /* Animation */
}

/* Animation for opening modal */
@keyframes modalOpen {
  to {
    opacity: 1;
  }
}

.modal p {
  margin-bottom: 20px; /* Increased margin for better spacing */
}

.wrapper {
  text-align: center;
}
.wrapper input {
  border-radius: 10px;
  height: 2.5rem;
  width: 10rem;
  font-size: 18px;
  margin-right: 10px;
}
/* .wrapper .button {
  border-radius: 10px;
  height: 2rem;
  width: 4rem;
  font-size: 15px;
  color: #028090;
  background-color: white;
  border: solid #028090 2px;
}

.wrapper .button:hover {
  background-color: #f0f3bd;
} */

.wrapper .button {
  border-radius: 10px;
  height: 2rem;
  width: 4rem;
  font-size: 15px;
  color: #f0f3bd; /* Changed text color to light green */
  background-color: #028090; /* Dark blue background */
  border: none; /* No border, but you can put it back if you prefer */
  transition: all 0.3s ease; /* Change 'background-color' to 'all' to include other properties */
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
}

.wrapper .button:hover {
  background-color: #00a896; /* Light green background on hover */
  color: white; /* Dark blue text on hover */
}

.wrapper .button:active {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.3); /* Reduce shadow size to create 'depressed' effect */
  transform: translateY(2px); /* Moves button slightly downwards */
}

.information {
  background-color: white;
  color: #028090;
  border: none;
}
.information:hover {
  background-color: white;
  color: #00a896;
  text-decoration: underline;
}

.wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper h1 {
  margin: 0px;
  margin: 15px 0px;
}

.wrapper br {
  margin: 0px;
}

.arrayWrapper {
  position: relative;
  display: flex;
  position: relative;
  /* border-left: 5px solid black;
  border-right: 5px solid black; */

  max-width: 100vw;
  margin: 0px;
  /* border: 5px solid black; */
}

.secondValue {
  position: absolute;
  left: -40px;
}

.stageButton {
  background-color: #028090;
  color: #f0f3bd;
  margin: 0px 10px;
  border-radius: 10px;
  height: 2rem;
  width: 5rem;
}

.allColumns {
  border: 5px solid black;
  /* position: relative; */
  width: 20px;
  height: 100%;
  margin: 0px;
  overflow: hidden;
}
.allRows {
  border-top: 5px solid black;
  width: 100%;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
}

.column-0 {
  background-color: rgb(155, 80, 246);
}
.column-1 {
  background-color: rgb(51, 99, 243);
}
.column-2 {
  background-color: rgb(75, 221, 241, 0.5);
}
.row-0 {
  background-color: rgb(246, 97, 80, 0.5);
}
.row-1 {
  background-color: rgb(233, 243, 51, 0.5);
}
.row-2 {
  background-color: rgb(101, 241, 97, 0.5);
}

.expandedFormTop {
  position: absolute;
  display: flex;
  justify-content: center;
  top: -40px;
  /* width: 100%; */
}
.invisibleParagraph {
  visibility: hidden;
}

.expandedFormLeft {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  left: -40px;
  /* top: 10px; */
}

.expandedFormLeft p {
  margin: 0px;
  display: inline;
  /* top: 10px; */
}

.topPlus {
  position: absolute;
}
.leftPlus {
  position: absolute;
}

.rightAddition {
  display: flex;
  flex-direction: column;
  position: absolute;
}

.rightAddition p {
  margin: 0px;

  /* justify-self: right; */
  align-self: flex-end;
}
.rightSolution {
  border-top: 1px solid black;
}

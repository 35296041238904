.card {
  width: 250px; /* This is the fixed width of the card */
  background-color: #f2f2f2; /* A light gray */
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  color: #333; /* Dark gray text for contrast */
  padding: 20px; /* Optional: Add some padding inside the card for better aesthetics */
  box-sizing: border-box; /* Ensures padding doesn't affect the total width of the card */
}
.card img {
  width: 80%;
  align-self: center;
}
.imgContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.title {
  text-align: center;
  text-decoration: underline;
}
.linkWrapper {
  text-decoration: none;
}

.stageButton {
  background-color: #028090;
  color: #f0f3bd;
  margin: 0px 10px;
  border-radius: 10px;
  height: 2rem;
  width: 5rem;
}

.stageButton:hover {
  background-color: #00a896;
}
.disabledButton:hover {
  background-color: grey;
}

.disabledButton {
  background-color: grey;
}

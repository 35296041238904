.textArea {
  margin: 10px;
  color: black;
  width: 30%;
  height: 200px;
}
.textContainer {
  display: flex;
  flex-direction: column;
}

.img {
  width: 100px;
}

.wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper h1 {
  margin: 0px;
  margin: 15px 0px;
}

.wrapper br {
  margin: 0px;
}

.arrayWrapper {
  position: relative;
  display: flex;
  position: relative;

  max-width: 100vw;
  margin: 0px;
  /* border: 5px solid black; */
}

.leftValues {
  position: absolute;
  font-size: 16px;
  left: -45px;
  display: block;
  width: 35px;
  text-align: end;
}
@media (max-width: 550px) {
  .leftValues {
    left: -35px;
    font-size: 14px;
  }
}

.totalLabel {
  position: absolute;
  left: -40px;
}

.stageButton {
  background-color: #028090;
  color: #f0f3bd;
  margin: 0px 10px;
  border-radius: 10px;
  height: 2rem;
  width: 5rem;
}

.allColumns {
  border: 2px solid black;
  /* position: relative; */
  width: 20px;
  height: 100%;
  margin: 0px;
  /* overflow: hidden; */
}
.allRows {
  border-top: 2px solid black;
  box-sizing: border-box;

  width: 100%;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
}
.column-0 {
  background-color: hsl(0, 100%, 70%);
}
.column-1 {
  background-color: hsl(30, 100%, 70%);
}
.column-2 {
  background-color: hsl(60, 100%, 70%, 0.5);
}
.row-0 {
  background-color: hsl(90, 100%, 70%, 0.5);
}
.row-1 {
  background-color: hsl(120, 100%, 70%, 0.5);
}
.row-2 {
  background-color: hsl(150, 100%, 70%, 0.5);
}
.row-3 {
  background-color: hsl(180, 100%, 70%, 0.5);
}
.row-4 {
  background-color: hsl(210, 100%, 70%, 0.5);
}
.row-5 {
  background-color: hsl(240, 100%, 70%, 0.5);
}
.row-6 {
  background-color: hsl(270, 100%, 70%, 0.5);
}
.row-7 {
  background-color: hsl(300, 100%, 70%, 0.5);
}
.row-8 {
  background-color: hsl(330, 100%, 70%, 0.5);
}
.row-9 {
  background-color: hsl(360, 100%, 70%, 0.5);
}

.expandedFormTop {
  position: absolute;
  display: flex;
  justify-content: center;
  top: -40px;
  /* width: 100%; */
}
.expandedFormTopOffset {
  position: absolute;
  display: flex;
  justify-content: center;
  top: -40px;
  /* width: 100%; */
}

@media (max-width: 700px) {
  .expandedFormTopOffset {
    top: -20px;
  }
}

.invisibleParagraph {
  visibility: hidden;
}

.expandedFormLeft {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  left: -40px;
  /* top: 10px; */
}

.expandedFormLeft p {
  margin: 0px;
  display: inline;
  /* top: 10px; */
}

.topPlus {
  position: absolute;
}
.leftPlus {
  position: absolute;
}

.rightAddition {
  display: flex;
  flex-direction: column;
  position: absolute;
}

.rightAddition p {
  margin: 0px;

  /* justify-self: right; */
  align-self: flex-end;
}
.rightSolution {
  border-top: 1px solid black;
}
.disk {
  /* background-color: rgba(16, 1, 1, 0.594); */
  background-color: #808080;

  border-radius: 50%;

  margin: 1px;
}

.diskContainer {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-left: 5px;
}
.diskContainerWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.diskBorder {
  border: 3px solid rgb(1, 1, 198);
  border-radius: 5px;
}
.diskContainerAnimation {
  opacity: 1; /* Start fully visible */
  transition: transform 1s, opacity 1s;
}
.fadeOut {
  opacity: 0; /* End completely transparent */
}

.diskHide {
  opacity: 0;
}
.diskAppear {
  opacity: 1;
  transition: opacity 1s;
}

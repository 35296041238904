.selectWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.selectWrapper label {
  margin-right: 5px;
}

.selectWrapper select {
  position: relative;
  display: inline-block;
  padding: 10px;
  font-size: 16px;
  font-family: sans-serif;
  color: #333;
  appearance: none;
  border: none;
  background-color: #f2f2f2;
  cursor: pointer;
}

/* 
select:after {
  content: '\25BC';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 20px;
  color: #666;
}

select:focus {
  outline: none;
}

select option {
  background-color: #f2f2f2;
  color: #333;
  font-size: 16px;
  font-family: sans-serif;
}

select option:checked {
  background-color: #0077cc;
  color: #fff;
}}
 */

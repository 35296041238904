.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999; /* Slightly lower z-index */
  opacity: 0;
  animation: modalOpen 0.5s forwards; /* Animation */
}
.modal {
  background-color: #028090;
  color: white;
  position: fixed; /* Changed from absolute to fixed */
  top: 50%; /* Changed for centering */
  left: 50%; /* Changed for centering */
  transform: translate(-50%, -50%); /* Centering */
  width: 80%;
  max-width: 500px; /* Limit width */
  height: auto;
  z-index: 1000; /* High z-index to ensure it's on top */
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5); /* Add shadow */
  padding: 20px; /* Add padding */
  font-family: "Comic Sans MS", "Chalkboard SE", "Marker Felt", sans-serif; /* Fun font */
  opacity: 0;
  animation: modalOpen 0.5s forwards; /* Animation */
}

/* .closeButton {
  background-color: #f0f3bd;
  color: #028090;
  border-radius: 10px;
  height: 2rem;
  width: 5rem;
} */

.closeButton {
  background-color: #f0f3bd;
  color: #028090;
  border: none; /* Removes default border */
  border-radius: 10px;
  padding: 10px 20px; /* Larger padding makes the button look more sleek */
  height: 2rem;
  width: 5rem;
  transition: all 0.3s ease; /* Animates button properties smoothly */
  cursor: pointer; /* Changes the cursor when hovering over the button */
  font-size: 1em; /* Makes the button text a bit larger */
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15); /* Adds some depth to the button */
}

.closeButton:hover {
  background-color: #00a896; /* Changes the background color when hovering */
  color: #f0f3bd; /* Changes the text color when hovering */
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2); /* Makes the shadow deeper when hovering */
}

.closeButton:active {
  transform: scale(0.98); /* Makes the button slightly smaller when clicked */
}

/* .closeButton:hover {
  background-color: #00a896;
  color: white;
} */

.closeButton p {
  width: 100%;
  height: 100%;
  text-align: center;
  justify-self: center;
  margin: 0;
}

.closeX {
  border: none;
  position: absolute;
  top: 0px;
  left: 0px;
  background: none;
  color: #999; /* adjust as needed */
  font-size: 2rem; /* adjust as needed */
  cursor: pointer;
}

.closeX:hover {
  color: rgb(207, 202, 202);
}

@keyframes modalOpen {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.wrapper {
  text-align: center;
}
.wrapper h1 {
  margin: 0px;
  margin: 15px 0px;
}

.wrapper br {
  margin: 0px;
}
.row {
  display: flex;
  width: 100%;
  justify-content: center;
  border-radius: 20px;
  margin-bottom: 5px;
}

.rowGroups {
  border: 1px solid #00a896;
}

.row img {
  max-height: 100%;
  max-width: 100%;
  /* border-top: 5px solid black; */
}

/* .columnGroups {
  border-left: 5px solid red;
  border-right: 5px solid red;
} */

.arrayWrapper {
  display: inline-block;
  position: relative;
  border-top: 5px solid black;
  border-left: 5px solid black;
  padding: 10px;
  max-width: 50vw;
  overflow: none;
}

.secondValue {
  position: absolute;
  left: -40px;
  top: 0px;
}
.addingRowDiagram {
  position: relative;
  left: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* align-content: center; */
}
.addingRowDiagram p {
  /* position: absolute;
  top: 50%;
  left: 50%; */
  margin: 0px;
  /* padding: auto; */
  /* text-align: center; */
  /* color: blue; */
}
.addingRowDiagram span {
  margin: 0px;
  padding: auto;
  text-align: center;
}
.firstPlus {
  visibility: hidden;
}
.addingRowDiagramSolution {
  border-top: 2px solid black;
}

.stageButton {
  background-color: #028090;
  color: #f0f3bd;
  margin: 0px 10px;
  border-radius: 10px;
  height: 2rem;
  width: 5rem;
}

.stageButton:hover {
  background-color: #00a896;
}
.disabledButton:hover {
  background-color: grey;
}

.disabledButton {
  background-color: grey;
}
